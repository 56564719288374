@tailwind base;
@tailwind components;
@tailwind utilities;

/* PhotoGallery.js de kullanılıyor */
.shadow-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 75px 75px -25px rgba(39, 39, 42, 1),
    inset 0 75px 75px -50px rgba(39, 39, 42, 0.8),
    /* Üstteki gölgenin yoğunluğunu arttırmak için ek */ inset 0 -100px 100px -25px
      rgba(39, 39, 42, 1),
    inset 0 -125px 125px -25px rgba(39, 39, 42, 0.8); /* Alttaki gölgenin yoğunluğunu arttırmak için ek */
  z-index: 10;
  pointer-events: none;
}

/* Calendar.js Takvim günleri için  */
.day-box {
  position: relative;
  background: linear-gradient(
    to top,
    rgba(255, 0, 0, 0.8) 0%,
    rgba(255, 0, 0, 0.4) var(--dolulukOrani),
    white var(--dolulukOrani),
    white 100%
  );
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: background 0.3s ease, transform 0.3s ease;
}

/* Hover sırasında mavi yarı saydam katman */
.day-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(238, 255, 0, 0.163); /* Yarı saydam mavi */
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Hover etkinliğini engellememek için */
  border-radius: 0.5rem;
}

.day-box:hover::after {
  opacity: 1;
}

.day-box:hover {
  /* transform: scale(1.05); */

  span {
    color: rgb(255, 166, 0);
  }
}
